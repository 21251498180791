<template>
    <v-btn
      color="color3"
      text
      :small="!xs"
      :x-small="xs"
      @click.stop="onEmailClick"
    >
      Message these teams ({{teams.length}})
    </v-btn>
</template>

<script>
import * as mutations from '@/store/MutationTypes'

export default {
  props: ['teams', 'tournamentId', 'xs'],
  computed: {

  },
  methods: {
    onEmailClick () {
      const teamIds = this.teams.map(m => m.teamId || m.team.id)
      this.$store.commit(mutations.SET_TEAM_TO_MSG, teamIds)
      this.$router.push({ name: 'tournament-admin', parameters: { tournamentId: this.tournamentId }, query: { tab: 'emailer' } })
    }
  }
}
</script>
